/* You can add global styles to this file, and also import other style files */
::-webkit-scrollbar {
  display: none;
}
.navbar-toggler {
  border: unset;
  .navbar-toggler-icon {
    background-image: unset;
    height: unset;
  }
}
