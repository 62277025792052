body{
    background-color: #0F3443;
}
$fonfamily: 'Poppins', sans-serif;


/*====================
1.Brand Primary Variables
====================*/
$neutral_40: #A0A7AF;
$neutral_80: #727981;
$neutral_60: #929196;
$neutral_20: #D1D6DC;
$neutral_10: #F8F8F8;
$brand-primary: #255a61;
$primary-blue-dark: #0B2732;
$brand-white: #fff;
$brand-gray: #9faeb4;
$banner-gray: #cfd6d9;
$brand-dark: #0f3443;
$brand-color: #0e4455;
$fields-color: #6f858e;
$nav-color: #09738e;
$btn-color: #0f4454;
$btn_bg: linear-gradient(135deg, #34e89e 0%, #2e85a7 100%);
$blue_text_color:#00d1ff;
$text-black:#000;
$bg-dark-blue:#274855;
$green-light:#5dedb1;
$blue-light-switch:#0692B4;
$orange-switch: #ECA264;
$heading_font: 64px;
$subheading: 24px;
$subheading1: 32px;
$blue_light_dark:#0F3443;
$pera: 14px;
$fs-16: 16px;
$fs-10: 10px;
$btnbg: url(../assets/images/background.png);
$background: url(../assets/images/hero.png);
$check: url(../assets/images/Check.svg);
$live_show: url(../assets/images/live_show.png);
$body_bg: url(../assets/images/Landing_page.png);
$section_bg: url(../assets/images/Slice-1.png);
$header_boy: url(../assets/images/header_boy.png);
$ask_question: url(../assets/images/ask_question.png);
$play_icon: url(../assets/images/play.svg);
$photo: url(../assets/images/Photo.png);
$faq_bg: url(../assets/images/faq_bg.png);
$footer_bg: url(../assets/images/footer_bg.png);
$vector: url(../assets/images/Vector-2.png);
$btnbg: url(../assets/images/background.png);
$mobile_view: url(../assets/images/mobile_view.png);
$Plus_Icon: url(../assets/images/plus_Icon.svg);
$minus_Icon: url(../assets/images/minus_Icon.svg);
$Plan_footer: url(../assets/images/plan_footer.png);
$shape_bottom: url(../assets/images/shape_bottom.png);
$shape_top: url(../assets/images/shape_top.png);
$vectordown: url(../assets/images/Vector.png);
$artist_bg: url(../assets/images/artist_bg.png);
$union_bg: url(../assets/images/Union.png);
$circle_bg: url(../assets/images/circle.png);
$check_bg: url(../assets/images/check.png);
$artist_footer_bg: url(../assets/images/artist_footer_bg.png);
$live__show: url(../assets/images/live__show.png);
$setting_bg: url(../assets/images/setting_bg.png);
$avtarimg: url(../assets/images/avtarimg.png);
$request_bg: url(../assets/images/request_bg.png);
$checked_bg: url(../assets/images/checked.png);
/*====================
1.responsive
====================*/

$fs-45: 45px;
$fs-32: 32px;
$fs-28: 28px;
$fs-26: 26px;
$fs-24: 24px;
$fs-22: 22px;
$fs-20: 20px;
$fs-18: 18px;
$fs-16: 16px;
$fs-14: 14px;

// spacing

$mt-16: 16px;