@import '~bootstrap/scss/bootstrap';
@import '~intl-tel-input/build/css/intlTelInput.min.css';
@import 'node_modules/ngx-toastr/toastr';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/ngx-bootstrap/datepicker/bs-datepicker';
@import 'node_modules/ngx-toastr/toastr';
@import 'global_mixin';
@import 'global_variables';

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}

body {
  font-family: $fonfamily;

  a {
    text-decoration: none;
  }
}
::-webkit-scrollbar {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.resize-none {
  resize: none;
}

button:focus {
  outline: none !important;
  border: none;
  box-shadow: none !important;
}

/*====================
Header css start
====================*/

.body_bg {
  background-color: $brand-dark;
  overflow-x: hidden;
}

.text-smaller {
  @media (min-width: 991px) {
    background-color: rgba(15, 52, 67, 0.9);
  }
}
.add_task_btn {
  background-image: linear-gradient(135deg, #34e89e 0%, #2e85a7 100%);
  border-radius: 8px;
  border: none;
  color: $brand-white;
  font-size: $fs-14;
  padding: 12px 32px;
  @include break(tablet) {
    padding: 10px 20px;
  }
}

.navbar {
  padding: 30px 0;

  @include break(tablet) {
    background-color: $brand-dark;
    padding: 10px 0;
  }

  .flexgrow {
    flex-grow: inherit;
  }

  .for_mobile {
    .navbar-nav {
      li {
        margin-top: 16px;

        @include break(tablet) {
          margin-right: 0px;
        }

        a {
          display: flex;
          align-items: center;
          width: 100%;
          background-color: #0b2732;
          border-radius: 8px;
          padding: 12px;
          font-weight: 400;
          font-family: $fonfamily;

          .svg_icon {
            margin-right: 14px;
          }
        }
      }
    }
  }

  .navbar-nav {
    li {
      margin-right: 15px;

      &:last-child {
        margin-right: 0px !important;
      }

      a {
        color: $brand-gray;
        font-size: $fs-14;
        font-weight: 700;
        text-decoration: none;
        font-family: $fonfamily;
      }

      .br-left {
        position: relative;

        &::after {
          position: absolute;
          content: '';
          width: 1px;
          height: 20px;
          background-color: $brand-white;
          left: -6px;
          opacity: 0.2;

          @include break(sm-mobile) {
            display: none;
          }
        }
      }
    }

    @include break(tablet) {
      background-color: $brand-dark;
      max-height: 300px;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;

      &::after {
        content: '';
        background-image: $mobile_view;
        position: absolute;
        width: 100%;
        height: 181px;
        background-repeat: no-repeat;
        left: 40%;
        bottom: -70px;
      }
    }

    @media only screen and (min-width: 562px) and (max-width: 564px) {
      background-color: $brand-dark;
      height: 260px !important;
      position: relative;

      &::after {
        content: '';
        background-image: $mobile_view;
        position: absolute;
        width: 100%;
        height: 181px;
        background-repeat: no-repeat;
        left: 40%;
        bottom: -90px;
      }
    }

    @media only screen and (min-width: 566px) and (max-width: 568px) {
      background-color: $brand-dark;
      height: 260px !important;
      position: relative;

      &::after {
        content: '';
        background-image: $mobile_view;
        position: absolute;
        width: 100%;
        height: 181px;
        background-repeat: no-repeat;
        left: 40%;
        bottom: -90px;
      }
    }

    @media only screen and (min-width: 479px) and (max-width: 480px) {
      background-color: $brand-dark;
      height: 260px !important;
      position: relative;

      &::after {
        content: '';
        background-image: $mobile_view;
        position: absolute;
        width: 100%;
        height: 181px;
        background-repeat: no-repeat;
        left: 40%;
        bottom: -90px;
      }
    }
  }
}

.banner_sec {
  padding: 200px 0 0 0;
  width: 100%;

  @include break(desktop) {
    padding: 110px 0 0 0;
  }
}

@include break(tablet) {
  .for_destop {
    display: none;
  }
}

.contact_us {
  padding: 175px 0 0 0;
  position: relative;
  @include break(tablet) {
    padding-top: 45px;
  }

  &::before {
    content: '';
    background-image: $footer_bg;
    position: absolute;
    left: 0;
    width: 100%;
    height: 1074px;
    background-repeat: no-repeat;
    bottom: 0;
  }

  .footer_bg_sec {
    position: relative;

    .app_ad {
      padding-top: 150px;

      @include break(mobile) {
        padding-top: 50px !important;
      }
    }

    .contact_icon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 260px;
        width: 100%;
      }

      &::after {
        content: '';
        background-image: $vector;
        position: absolute;
        width: 650px;
        height: 550px;
        left: -130px;
        top: -90px;

        @include break(tablet) {
          display: none;
        }
      }

      @include break(desktop) {
        img {
          width: 100%;
        }
      }

      @include break(tablet) {
        img {
          width: 50px;
        }
      }

      @include break(tablet) {
        text-align: center;
      }
    }
  }

  .contact_detail {
    @include break(tablet) {
      text-align: center;
    }

    h1 {
      color: $brand-white;
      font-size: $subheading1;
      font-weight: 600;
      font-family: $fonfamily;

      @include break(tablet) {
        font-size: $fs-24;
      }
    }

    p {
      color: $banner-gray;
      font-family: $fonfamily;
      font-weight: 400;
      font-size: $fs-24;

      @include break(tablet) {
        font-size: $fs-18;
      }
    }

    .cont_fields {
      margin-top: 20px;

      .name_fields {
        display: flex;
        width: 100%;

        @include break(tablet) {
          display: inline-block;
        }
      }

      .name_inputfield {
        width: 40%;
        margin-right: 10px;

        @include break(tablet) {
          text-align: left;
          width: 100%;
        }

        label {
          width: 100%;
          font-size: 12px;
          color: $fields-color;
          margin-bottom: 5px;
        }

        input {
          height: 48px;
          background: $bg-dark-blue;
          border-radius: 8px;
          width: 100%;
          border: none;
          color: $brand-gray;
          padding-left: 15px;

          &:focus {
            outline: none;
          }
        }

        &:last-child {
          margin-right: 0px;
        }
      }

      .msg_field {
        margin-top: 15px;

        textarea {
          background: $bg-dark-blue;
          border-radius: 8px;
          width: 100%;
          height: 264px;
          border: none;
          color: $brand-gray;
          padding-left: 15px;
          padding-top: 10px;

          &:focus {
            outline: none;
          }
        }
      }

      .submit_btn {
        margin-top: 16px;

        button {
          @include btn-action;

          @include break(tablet) {
            width: 100%;
          }
        }
      }
    }
  }
}

.pt-150 {
  padding-top: 150px !important;
}

.app_ad {
  .find_music {
    @include break(tablet) {
      text-align: center;
    }

    h1 {
      font-size: $heading_font;
      font-weight: 600;
      color: $brand-white;
      font-family: $fonfamily;

      @include break(tablet) {
        font-size: $fs-24;
      }
    }
  }

  .download_app {
    text-align: right;
    position: relative;

    @include break(tablet) {
      text-align: center;
      margin-top: 20px;
    }

    &::before {
      content: '';
      position: absolute;
      background-image: $btnbg;
      width: 100%;
      height: 211px;
      right: -39px;
      top: -40px;
      z-index: 0;

      @include break(tablet) {
        display: none;
      }
    }

    p {
      font-size: $fs-24;
      color: $banner-gray;
      font-family: $fonfamily;

      @include break(mobile) {
        font-size: $fs-18;
      }
    }

    .app_icons {
      position: relative;
      display: flex;
      justify-content: end;

      a {
        margin-left: 10px;
      }

      @include break(tablet) {
        justify-content: center;
      }
    }
  }
}

.footer_sec {
  padding-top: 150px;
  position: relative;

  @include break(mobile) {
    padding-top: 50px;
  }

  .footer_menu {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include break(tablet) {
      align-items: center;
      flex-direction: column;
    }

    .f_menu {
      @include break(mobile) {
        width: 100%;
      }

      ul {
        margin: 0;
        padding: 0;
        display: flex;

        @include break(tablet) {
          margin-top: 15px;
        }

        @include break(mobile) {
          display: inline-block;
          width: 100%;
          text-align: center;
        }

        li {
          list-style: none;
          margin-right: 25px;

          @include break(mobile) {
            margin-right: 0px;
            margin-bottom: 20px;
          }

          &:last-child {
            margin-right: 0;
          }

          a {
            font-weight: 600;
            font-size: $pera;
            color: $brand-white;
            font-family: $fonfamily;
            text-decoration: none;
          }
        }
      }
    }
  }

  .footer_copyright {
    padding-top: 50px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;

    @include break(tablet) {
      display: inline-block;
      width: 100%;
      text-align: center;
    }

    .footer_main {
      display: flex;
      align-items: center;
      width: 50%;
      justify-content: space-between;

      @include break(tablet) {
        justify-content: space-between;
        width: 100%;
        margin-bottom: 37px;
      }
    }

    .privacy {
      a {
        color: $brand-white;
        font-size: $pera;
        font-family: $fonfamily;
        text-decoration: none;
        transition: 0.3s all;
        margin-right: 30px;
      }
    }

    .copyright {
      p {
        margin-bottom: 0;
        color: $brand-white;
        font-size: $pera;
        font-family: $fonfamily;
        margin-bottom: 0;
      }
    }

    .social_media {
      ul {
        padding: 0;
        margin: 0;
        display: flex;

        @include break(tablet) {
          justify-content: center;
        }

        li {
          list-style: none;
          margin-right: 50px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.choose_plan {
  background-color: $brand-white;
  transform: skew(360deg, 352deg);
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  background-size: cover;
  padding: 15rem 0;
  margin-top: -110px;
  overflow: hidden;
  position: relative;
  @include break(tablet) {
    transform: unset;
  }

  @include break(desktop) {
    padding: 10rem 0 100px 0;
  }
  @include break(tablet) {
    padding: 4rem 0 100px 0;
  }

  .container {
    transform: skew(-360deg, -352deg);
    @include break(tablet) {
      transform: unset;
    }
  }

  .choose_plan_heading {
    text-align: center;

    h1 {
      margin-top: 40px;
      font-size: $subheading1;
      font-weight: 600;
      color: $brand-dark;
      margin-bottom: 20px;
      font-family: $fonfamily;

      @include break(tablet) {
        font-size: $fs-24;
      }
    }

    p {
      font-size: $subheading;
      font-family: $fonfamily;
      color: $brand-gray;
      font-weight: 400;
      margin-top: 10px;
      margin-bottom: 50px;

      @include break(tablet) {
        font-size: $fs-18;
      }
    }
  }

  .plan_details {
    padding: 32px;
    height: auto;
    transition: 0.3s all;
    border-radius: 16px;

    @include break(tablet) {
      padding: 20px 15px;
      background-color: rgba(207, 214, 217, 0.2);
    }

    &:hover {
      background-color: rgba(207, 214, 217, 0.2);
    }

    h5 {
      font-weight: 600;
      font-size: $fs-16;
      font-family: $fonfamily;
      color: $brand-dark;
      margin: 0;
    }

    h6 {
      font-size: $fs-10;
      font-family: $fonfamily;
      font-weight: 600;
      color: $brand-gray;
      margin-top: 5px;
      margin-bottom: 0;
    }

    h1 {
      font-size: $fs-24;
      font-family: $fonfamily;
      font-weight: 600;
      color: $brand-dark;
      margin-top: 5px;
      margin-bottom: 0;
    }

    p {
      font-size: $pera;
      font-family: $fonfamily;
      font-weight: 400;
      color: $brand-gray;
      margin-top: 5px;
      margin-bottom: 0;
    }

    .get_started {
      margin-top: 16px;

      button {
        @include btn-action;
      }
    }

    ul {
      margin-top: 15px;
      padding-left: 21px;
      margin-bottom: 0;

      li {
        list-style: none;
        font-size: $pera;
        font-family: $fonfamily;
        font-weight: 400;
        color: $brand-dark;
        margin-bottom: 10px;
        position: relative;

        &:after {
          background-image: $check;
          content: '';
          position: absolute;
          width: 13px;
          height: 12px;
          left: -23px;
          top: 4px;
        }
      }
    }
  }
}

@include break(tablet) {
  .pricing_for_web {
    display: none;
  }
}

/*====================
Toast Component Css
====================*/

.toast-container {
  width: 100% !important;
  display: flex;
  padding: 8px 15px 8px 50px !important;

  .custom-toast {
    display: flex;
    align-items: center;

    &.error_msg_title {
      display: flex;
      align-items: center;
    }

    &.toast-title {
      margin-right: 10px;
    }

    &.toast-top-right {
      top: 12px;
      right: 43px !important;
    }

    .toast_cross_icon {
      margin-left: auto !important;
    }

    .toast-message {
      color: $text-black;
    }

    &.toast-success {
      background-image: url('../../../libs/theme/assets/images/check_icon.png') !important;
      background-color: $brand-white;
      display: flex;
      margin-top: 15px;
      border-radius: 8px;
      align-items: center;
    }

    &.toast-error {
      background-image: url('../../../libs/theme/assets/images/red_cross.png') !important;
      background-color: $brand-white;
      display: flex;
      margin-top: 15px;
      border-radius: 8px;
      align-items: center;
      height: 48px;
    }

    .toast-close-button {
      right: 2px !important;
      top: -3px !important;
    }
  }
}

.custom-popover {
  width: 275px;
  border-radius: 10px;
  background-color: $brand-dark;
  z-index: 9;
  .popover-body {
    padding: 0;
  }
  ul {
    padding: 20px;
    margin: 0;
    position: relative;
    li {
      list-style: none;
      border-bottom: 1px solid #0f3443;
      .menuli {
        display: flex;
        align-items: center;
        padding-bottom: 10px;

        h6 {
          color: $brand-white;
          margin-bottom: 0;
          padding-left: 10px;
        }
      }
      .active_li {
        display: flex;
        align-items: center;
        padding: 12px 0;
        justify-content: space-between;

        label {
          width: 100%;
          color: $fields-color;
          font-family: $fonfamily;
          font-size: 12px;
          font-weight: 400;
          padding-left: 5px;
        }
        h6 {
          color: $brand-white !important;
        }

        input {
          background: $bg-dark-blue;
          height: 48px;
          border-radius: 8px;
          width: 100%;
          border: none;
          padding-left: 15px;
          color: $brand-gray;
          font-family: $fonfamily;
          font-size: $pera;
          padding-right: 35px;

          &:focus {
            outline: none;
          }
        }

        p {
          color: $blue_text_color;
          font-weight: 500;
          margin-bottom: 0;
          cursor: pointer;
          font-family: $fonfamily;
        }

        span {
          position: absolute;
          right: 10px;
          top: 35px;
        }

        .switch {
          position: relative;
          display: inline-block;
          width: 48px;
          height: 24px;

          input {
            opacity: 0;
            width: 0;
            height: 0;
          }

          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $brand-dark;
            -webkit-transition: 0.4s;
            transition: 0.4s;

            &::before {
              position: absolute;
              content: '';
              height: 16px;
              width: 16px;
              left: 3px;
              bottom: 4px;
              background-color: $brand-white;
              transition: 0.4s;
              border-radius: 50% !important;
            }
          }

          .slider.round {
            border-radius: 15px;

            &::before {
              border-radius: 4px;
            }
          }

          input:checked + .slider {
            background-color: #33de9f;
            border: 0;
            border: 0;
          }

          input:focus + .slider {
            box-shadow: 0 0 1px $brand-dark;
          }

          input:checked + .slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
          }
        }

        .privacy_policy {
          display: flex;
          align-items: center;

          .toggle_btn {
            margin-right: 10px;
          }
        }

        .privacy_text {
          p {
            color: $brand-white;
            font-family: $fonfamily;
            font-size: $fs-16;
            font-weight: 400;

            a {
              color: $blue_text_color;
              text-decoration: none;
            }
          }
        }

        h6 {
          color: $text-black;
          margin-bottom: 0px;
        }
      }

      &:last-child {
        border: none;
      }

      .remove {
        display: flex;
        align-items: center;
        text-align: center;
        width: 100%;
        justify-content: center;
        margin-top: 12px;

        h6 {
          color: $brand-white;
          margin-bottom: 0;
          margin-left: 5px;
        }
      }
    }
  }
}
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::after {
  border-bottom-color: $bg-dark-blue;
}

/*====================
Custom Pagination
====================*/

.custom-pagination {
  pagination {
    display: flex;
    justify-content: center;
    .pagination {
      margin-left: auto;
      padding-top: 0;
      overflow-x: auto;
      border-radius: 5px;
      display: flex;
      margin-left: auto;

      .page-item {
        color: rgba(0, 0, 0, 0.5) !important;
        font-weight: 500;
        transition: all 0.3s;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-right: 0;
        margin: 0;
        border-radius: 0;
        background: #22414d;

        .page-link {
          color: $brand-white;
          border: none;
          background-color: transparent;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
        &.active {
          color: $brand-white !important;
          border: 1px solid #22414d;
          background: #22414d;

          &:hover {
            background: #22414d;
            color: $brand-white !important;
            border: 1px solid #22414d;
          }
        }

        &:hover {
          background: rgba(#22414d, 0.1);
          color: #22414d !important;
          border: 1px solid rgba(#22414d, 0.1);
          border-right: 0;
        }

        &.disabled {
          background: #22414d;
          color: #f9fbfc !important;
          cursor: no-drop;
        }
      }
      &.pagination-first {
        border-radius: 5px 0 0 5px;
      }

      &.pagination-last {
        border-radius: 0 5px 5px 0;
        border: 1px solid rgba(0, 0, 0, 0.1);

        &:hover {
          border-right: 1px solid #22414d;
        }
      }
    }
  }
}

.custom-datepicker {
  background: $brand-primary;
  box-shadow: 0 0 10px 0 $brand-primary;
}

a.nav-link.active {
  color: $brand-white !important;
}

.modal {
  .custom-model {
    max-width: 600px;
    @include break(mobile) {
      margin: 0 auto;
    }

    .modal-content {
      background: #0b2732;
      @include break(mobile) {
        margin: 0 10px;
      }

      .header {
        color: $brand-white;
        font-size: $fs-24;
      }

      .close {
        position: absolute;
        right: 15px;
        top: 17px;
        max-width: 17px;
        width: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.buy-ticket-modal {
  max-width: 700px !important;

  .modal-content {
    padding: 10px 22px 30px 22px;
    background: $bg-dark-blue;
    border-radius: 8px;

    @include break(mobile) {
      padding: 20px;
    }

    @include break(sm-mobile) {
      padding: 10px;
    }
  }
}

.send-fan-modal {
  max-width: 650px !important;

  .modal-content {
    padding: 15px 22px 40px 40px;
    background: $bg-dark-blue;
    border-radius: 8px;

    @include break(mobile) {
      padding: 20px;
    }

    @include break(sm-mobile) {
      padding: 10px;
    }
  }
}

.Withdraw-ticket-modal {
  max-width: 700px !important;

  .modal-content {
    padding: 10px 22px 40px 40px;
    background: $bg-dark-blue;
    border-radius: 8px;

    @include break(mobile) {
      padding: 20px;
    }

    @include break(sm-mobile) {
      padding: 10px;
    }
  }
}

.modal .video-modal-custom .modal-content {
  padding: 0px 38px 40px 54px !important;
  @include break(sm-mobile) {
    padding: 20px !important;
  }
  background-image: url(/assets/images/video-modal.png);
  background-color: none;
  height: 504px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  text-align: center;
  position: relative;
  border: none !important;
  background-size: cover;
  background-repeat: no-repeat;
  @include break(mobile) {
    margin-top: auto;
  }
}
.modal .voice-modal-custom .modal-content {
  background-image: url(/assets/images/voice-modal.png);
}

.spinner {
  cursor: pointer;
  width: 15px;
  height: 15px;
  position: absolute;
  background: transparent;
  border-top: 3px solid $brand-white;
  border-right: 3px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-tooltip {
  .custom-tooltip {
    display: none;
    position: absolute;
    bottom: calc(100% + 10px);
    left: 0;
    transform: translateX(-50%);
    background-color: #000000;
    color: $brand-white;
    font-size: 13px;
    padding: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    width: 270px;

    @media (max-width: 380px) {
      width: 200px;
    }
  }

  &:hover {
    .custom-tooltip {
      display: block;
    }
  }
}
.main_content {
  margin-left: 230px;
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_content-wrapper {
  display: flex;
  justify-content: center;
  align-items: start;
}
.category_cross {
  img {
    max-width: 18px;
    width: 100%;
  }
}
.skit_back {
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    max-width: 17px;
    width: 100%;
    height: 17px;
    margin-right: 10px;
  }
}
.skit_mainrow {
  .table-row {
    .dataTables_scroll {
      .dataTables_scrollHeadInner {
        width: 100% !important;
        table {
          width: 100% !important;
        }
      }
    }
  }
}
.upload-btn{
  display: none;
  position: fixed;
  bottom: 80px;
  right: 10px;
  z-index: 3;
  padding: 10px 20px;
  background: linear-gradient(135deg, #34e89e 0%, #2e85a7 100%);
  border: none;
  font-size: 14px;
  border-radius: 8px;
  color: white; 
  @include break(mobile) {
    display: block;
  }
}