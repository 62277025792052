// #media-query
@mixin break($breakpoint) {
  @if $breakpoint == desktop {
    @media (max-width: 1200px) { @content; }
  } @else if $breakpoint == tablet {
    @media (max-width: 992px) { @content; }
  } @else if $breakpoint == mobile {
    @media (max-width: 767px) { @content; }
  } @else if $breakpoint == sm-mobile {
    @media (max-width: 575px) { @content; }
  }
}

/*====================
1. button Mixin css start
====================*/

@mixin btn-action {
  padding: 12px 30px;
  background: $btn_bg;
  border-radius: 8px;
  font-weight: 600;
  font-size: $fs-14;
  color: $brand-white;
  border: 0;
  transition: all 0.3s ease;
  font-family: $fonfamily;
  cursor: pointer;
  padding: 12px 30px;
}
table{
  margin: 0 !important;
}

@mixin btn-green-border {
  background: $brand-white;
  border-radius: 8px;
  font-weight: 600;
  font-size: $fs-14;
  color: #000000;
  border: 1px solid $brand-white;
  transition: all 0.3s ease;
  font-family: $fonfamily;
  max-width: max-content;
  cursor: pointer;
  padding: 12px 30px;
}


input:checked + .slider {
  background-color: #00d1ff;
}
.continue_btn{
  display: flex;
  button{
    padding: 0 32px;
  }
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: white !important;
}
select{
  border: 0px solid black !important;
  border-right-width: 10px !important;
  border-color: transparent !important;
}
